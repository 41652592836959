// mini-sab
.mini-sab {
	@include media-breakpoint-down(sm) {
		background: $purple;
		margin: 0 10px;
	}

	.container {
		position: relative;
		display: flex;

		@include media-breakpoint-down(sm) {
			justify-content: center;
		}

		@include media-breakpoint-up(md) {
			justify-content: flex-end;
		}

		.container-holder {
			z-index: 100;
			position: relative;
			max-width: 350px;
			margin: -50px 15px 0 15px;
			padding: 40px 25px 70px 25px;
			background: $white;
			overflow: hidden;
			@include media-breakpoint-down(xs) {
				padding-left: 5px;
				padding-right: 5px;
			}
			@include media-breakpoint-up(md) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: -80px;
				margin: 0;
			}

			&::after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				width: 101%;
				height: 25px;
				margin-bottom: -1px;
				background: url('/images/wave-purple.svg') no-repeat bottom;
				background-size: 101% auto;
			}

			// image
			.image {
				margin-bottom: 15px;
			}
		}
	}
}
