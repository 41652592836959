// btn
.btn {
	display: inline-flex;
	align-items: center;

	@include media-breakpoint-up(xl) {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}

	border-radius: 3px;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;

	&::after {
		content: "\f30b";
		margin-left: 10px;
		font-size: 24px;
		font-weight: 400;
		line-height: 1;
		font-family: $font-awesome;
	}

	// btn-primary
	&.btn-primary {
		&:hover {
			background-color: $red;
			border-color: $red;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {}
	}
}

// card-btn
.card-btn {
	@extend .link-arrow;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
	&::after {
		content: "\f0d7";
		font-weight: 900;
		font-size: inherit;
	}
}
