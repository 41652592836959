// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 75vh;
	min-height: 540px;
	max-height: 720px;
}

// eyecatcher
.eyecatcher {
	margin: 10px;

	@include media-breakpoint-up(xl) {
		margin: 20px;
	}

	.owl-carousel {
		.item {
			align-items: flex-end;

			&::after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 50%;
				max-height: 300px;
				background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.5) 100%);
			}

			.owl-container {
				z-index: 100;
				position: relative;

				@include media-breakpoint-only(md) {
					max-width: 370px;
				}

				@include media-breakpoint-only(lg) {
					max-width: 600px;
				}

				@include media-breakpoint-up(xl) {
					max-width: 785px;
				}

				margin-left: 0;

				.owl-caption {
					display: flex;
					flex-direction: column;

					@include media-breakpoint-down(sm) {
						margin-bottom: 60px;
					}

					@include media-breakpoint-between(md, lg) {
						padding: 25px 10px;
					}

					@include media-breakpoint-up(lg) {
						padding: 40px 25px;
					}

					text-align: left;
					text-shadow: none;

					.owl-title {
						margin: 0;
						font-size: 24px;
						font-weight: 400;
						font-style: italic;
						font-family: $font-family-tertiary;
					}

					.owl-subtitle {
						margin: 0;
						@include font-size($h1-font-size);

						@include media-breakpoint-up(xl) {
							font-size: 40px;
						}

					}
				}
			}
		}
	}

	// owl
	.owl-dots,
	.owl-nav {
		display: none;
	}

}
