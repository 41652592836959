// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	.container-one-column {
		.container-holder {
			max-width: 720px;
			margin: 0 auto;
			text-align: center;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;

	// intro/outro
	.intro,
	.outro {
		max-width: 720px;

		.container-holder {
			text-align: center;
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

// collection-section
// =========================================================================
.collection-section {
	@include media-breakpoint-up(md) {
		padding-top: 100px !important;
	}

	// intro
	.intro {
		max-width: 720px;

		.container-holder {
			margin-bottom: 6vh;
			text-align: center;

			.title {
				h5 {
					color: $yellow;
				}
			}

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	// collection
	.collection {
		.card {
			border-radius: 0;
			border: none;

			.card-image {
				.card-image-link {
					position: relative;

					&::after {
						content: "";
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0;
						width: 101%;
						height: 25px;
						margin-bottom: -1px;
						background: url('/images/wave-white.svg') no-repeat bottom;
						background-size: 101% auto;
					}
				}
			}

			.card-body {
				.card-caption {
					display: flex;
					flex-direction: column;

					.card-subtitle {
						order: -1;
						margin: 0;
						color: $blue;
						font-size: 24px;
						font-weight: 400;
						font-style: italic;
						font-family: $font-family-tertiary;
					}

					.card-title {
						@include font-size($h2-font-size);
						color: $purple-dark;
					}
				}
			}
		}
	}

}

// simple-collection-section
// =========================================================================
.simple-collection-section {

	// intro
	.intro {
		max-width: 720px;

		.container-holder {
			margin-bottom: 15px;
			text-align: center;

			.title {
				h5 {
					color: $yellow;

					&::before {
						display: none;
					}
				}
			}

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	// collection
	.collection {
		@include media-breakpoint-only(sm) {
			max-width: 540px;
		}

		@include media-breakpoint-only(md) {
			max-width: 720px;
		}

		@include media-breakpoint-only(lg) {
			max-width: 960px;
		}

		@include media-breakpoint-up(xl) {
			max-width: 1140px;
		}

		margin: 0 auto;
		padding: 30px 15px;

		// grid
		&.grid {
			.grid-items {
				@include media-breakpoint-down(xs) {
					margin: 0 -5px -10px -5px;
				}

				.item {
					@include media-breakpoint-down(xs) {
						@include make-col(6);
						margin-bottom: 10px;
						padding-left: 5px;
						padding-right: 5px;
					}
				}
			}
		}

		.card {
			border-radius: 0;
			border-color: $purple;

			.card-image {
				position: relative;

				&::after {
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					width: 101%;
					height: 25px;
					margin-bottom: -1px;
					background: url('/images/wave-purple-light.svg') no-repeat bottom;
					background-size: 101% auto;
				}
			}

			.card-body {
				background-color: $purple-light;

				.card-caption {
					display: flex;
					flex-direction: column;

					.card-title {
						color: $white;
						font-size: 20px;
						font-weight: 400;
						font-style: italic;
						font-family: $font-family-tertiary;
					}

					.card-subtitle,
					.card-description {
						display: none
					}
				}
			}
		}
	}

	// outro
	.outro {
		.link {
			margin: 0 auto;
		}
	}

}

// banner-section
// =========================================================================
.banner-section {
	margin: 6vh 0 10vh 0;

	// owl-carousel
	.owl-carousel {
		height: 75vh;
		min-height: 480px;
		max-height: 720px;

		.owl-stage-outer {
			padding-bottom: 30px;
		}

		.item {
			height: 75vh !important; // overide
			min-height: 480px;
			max-height: 720px;
			padding: unset; // overide
			align-items: flex-end;

			.owl-caption {
				position: relative;
				max-width: 350px;
				min-height: 350px;
				margin-bottom: -30px;
				padding: 40px 30px 80px 30px;
				background: $purple;
				text-shadow: none;
				text-align: left;
				overflow: hidden;

				&::after {
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					width: 101%;
					height: 30px;
					margin-bottom: -1px;
					background: url('/images/wave-white.svg') no-repeat bottom;
					background-size: 101% auto;
				}

				.owl-title {
					color: $yellow;
					font-size: 24px;
					font-weight: 400;
					font-style: italic;
					font-family: $font-family-tertiary;
				}

				.owl-subtitle {
					@include font-size($h2-font-size);
				}
			}
		}

		// owl-nav
		.owl-nav {
			display: none;
			z-index: 101;
			position: absolute;
			left: 15px;
			right: 15px;
			bottom: 0;
			max-width: 350px;
			height: 40px;

			@include media-breakpoint-up(xl) {
				margin-left: calc((100vw / 2 - 570px));
			}

			.owl-prev,
			.owl-next {
				top: auto;
				transform: none;
				font-size: 20px;
				text-shadow: none;
			}

			.owl-prev {
				left: 0;

				&:hover {
					left: -3px;
				}
			}

			.owl-next {
				right: 0;

				&:hover {
					right: -3px;
				}
			}
		}

		// owl-dots
		.owl-dots {
			position: absolute;
			left: 15px;
			right: 15px;
			bottom: 0;
			max-width: 350px;
			height: 40px;

			@include media-breakpoint-up(xl) {
				margin-left: calc((100vw / 2 - 570px));
			}

			.owl-dot {
				margin: 3px;

				span {
					position: relative;
					width: 19px;
					height: 19px;
					margin: 0;
					background: transparent;
					border: 1px solid $white;
					border-radius: 100%;
					box-shadow: none;
					transition: 0.5s;
				}

				&:focus,
				&:hover,
				&.active {
					span {
						background-color: $purple-dark;
						border-color: $purple-dark;
					}
				}
			}
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card;
		}
	}

}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 6vh 0;
	padding: 6vh 0;
	background-color: $purple-light;
	@extend .heart-pattern-bg;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}
