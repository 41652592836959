// footer
.footer {
	margin-top: 8vh;

	// footer-top
	.footer-top {
		background: url('/images/footer-bg.png') no-repeat left bottom;
		padding-bottom: 150px;

		h5,
		h6 {
			color: $yellow;
			font-size: 24px;
			font-weight: 400;
			font-style: italic;
			font-family: $font-family-tertiary;
		}

		h6 {
			font-size: 20px;
		}

		// logolink
		.footer-logolink {
			.list {
				.list-item {
					margin-right: 15px;

					.link {
						display: block;
						font-size: 24px;
					}
				}
			}
		}

		.container-one-column {
			margin-bottom: 6vh;

			.footer-text {
				p {
					max-width: 520px;
				}
			}
		}
	}

	// footer-copyright
	.footer-copyright {
		padding: 30px 0;

		ul {
			display: flex;
			flex-flow: row wrap;
			@include media-breakpoint-down(sm) {
				flex-direction: column;
			}
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin-right: 30px;

				a {
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

}
