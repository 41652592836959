& {
	font-size: 15px;
}

// main
.main {
	margin: 0 10px;

	@include media-breakpoint-up(xl) {
		margin: 0 20px;
	}

}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// headings
strong {
	font-weight: 700;
}

.title,
.subtitle {
	h5 {
		position: relative;
		margin-bottom: 5px;
		color: $blue;
		font-size: 24px;
		font-weight: 400;
		font-style: italic;
		font-family: $font-family-tertiary;

		&::before {
			content: "";
			display: block;
			width: 53px;
			height: 49px;
			margin: 0 auto 10px auto;
			background: url('/images/heart.svg') no-repeat;
		}
	}
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	border-radius: 0;
	border: none;
	@extend .bg-light;

	.card-image {
		.card-image-link {
			position: relative;

			&::after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				width: 101%;
				height: 25px;
				margin-bottom: -1px;
				background: url('/images/wave-purple.svg') no-repeat bottom;
				background-size: 101% auto;
			}
		}
	}

	.card-body {
		.card-caption {
			display: flex;
			flex-direction: column;

			.card-subtitle {
				order: -1;
				margin: 0;
				color: $yellow;
				font-size: 24px;
				font-weight: 400;
				font-style: italic;
				font-family: $font-family-tertiary;
			}
		}
	}
}

// link-arrow
a.link-arrow {
	display: block;
	width: 50px;
	height: 50px;
	border: none;
	border-radius: 100%;
	background-color: $red;
	color: $white;
	font-size: 24px;
	font-weight: 700;
	text-align: center;
	line-height: 50px;
	text-decoration: none !important;

	&::before {
		content: "\f30b";
		font-size: 24px;
		font-weight: 400;
		font-family: $font-awesome;
		line-height: 1;
	}

	&:hover {
		background-color: $red-dark;
		color: $white;
	}
}

// heart-pattern-bg
.heart-pattern-bg {
	background: url('/images/heart-pattern.svg');
	background-size: 160px 189px;
}

// info-nav
&.accommodation-detail {
	.info-nav {
		justify-content: center !important;
		.back-to-overview-link {
			display: none !important;
		}
	}
	.info {
		order: -1;
	}
}
