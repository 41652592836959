// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: url('/images/header-bg.png') no-repeat left bottom $white;
	transition: 0.5s;
	box-shadow: $shadow;

	.container-fluid {
		.container-holder {
			>.column {
				padding: 0;

				@media screen and (min-width: 1400px) {
					padding-left: 30px;
				}

				@extend .align-items-center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			width: 60px;
			height: 60px;
			margin-right: 15px;
			padding: 0;
			border: none;
			border-radius: 0;
			background: $purple-dark;
			color: $white;
			font-size: 24px;
			font-weight: 700;
			font-family: $font-family-secondary;
			text-transform: uppercase;
			transition: color 0.5s;

			i {
				line-height: 60px;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 175px;
		margin-right: auto;

		@include media-breakpoint-up(sm) {
			width: 200px;
		}

		@include media-breakpoint-up(xl) {
			width: 280px;
			margin: 12px auto 10px 20px;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 4;

			.navbar-nav {
				margin: 15px;

				>.nav-item {
					font-weight: 700;
					border-top: 1px solid $purple-dark;

					&:first-of-type {
						border-top: none;
					}
				}

				.nav-item {
					.nav-link {
						color: $purple-dark;

						&:hover {
							color: $red-dark;
						}
					}

					&.active {
						.nav-link {
							color: $red-dark;
						}
					}

					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 20px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 15px;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				margin-left: auto;

				>.nav-item {
					margin-right: 10px;

					>.nav-link {
						color: $purple-dark;
						font-size: 14px;
						font-weight: 700;
						text-transform: uppercase;

						&:hover {
							color: $red-dark;
						}
					}

					&.active {
						>.nav-link {
							color: $red-dark;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 0.5rem;

						.nav-item {
							font-size: 14px;
							font-weight: 700;
							line-height: 1.2;
							white-space: nowrap;
							text-transform: uppercase;

							.nav-link {
								color: $purple-dark;

								&:hover {
									color: $red-dark;
								}
							}

							&.active {
								.nav-link {
									color: $red-dark;
								}
							}
						}
					}
				}
			}
		}
	}

	// book-button
	div.book-button {
		margin-left: 10px;

		ul {
			li {
				a {
					display: flex;
					align-items: center;

					@include media-breakpoint-down(xs) {
						width: 100px;
					}

					height: 60px;
					padding: 0 15px;

					@include media-breakpoint-up(xl) {
						height: 80px;
						padding: 0 30px;
					}

					background: $red-dark;
					color: $white;
					font-size: 14px;
					font-weight: 700;
					line-height: 20px;
					text-align: center;
					text-decoration: none;
					text-transform: uppercase;
					font-family: $font-family-primary;

					&:hover {
						background: $blue;
					}
				}
			}
		}
	}

}
